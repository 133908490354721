import { useState } from "react";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../services/urls";
import useSWR from "swr";
import AdminManage from "./manage";
import { LuTrash } from "react-icons/lu";
import { confirmDeletion } from "../../../components/dletion/index";
import { Link } from "react-router-dom";
import { DEFAULT_PAGE_COUNT } from "../../../components/utils/consts";
import { Row } from "reactstrap";
import { formatDate } from "../../../components/utils/date.utils";
import { isManager } from "../../../components/utils/functions";
import Pagination from "../../../components/utils/pagination"; // Crée ce composant de pagination

const AdminList = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [toEdit, setToEdit] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const itemsPerPage = 10; // Nombre d'éléments par page

  const { data, isLoading, mutate } = useSWR(URLS.USERS.list);
  //console.log(data)

  const { t } = useTranslation();

  const handleSearch = () => {
    setSearch(inputSearch.toLowerCase());
  };

  // Filtrage des utilisateurs en fonction de l'email
  const filteredData = data?.results?.filter((user) =>
    user.email.toLowerCase().includes(search.toLowerCase())
  );

  // Calculer les éléments à afficher en fonction de la page
  const paginatedData = filteredData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handleChangePage = (pg) => {
    setPage(pg);
  };

  const handleEdit = (data) => {
    setToEdit(data);
    window.$("#manageIndustryForm").modal("show");
  };

  const onSuccess = () => {
    mutate();
  };

  return (
    <div className="dashboard-content">
      <div className="box-content">
        <div className="box-title">
          <h3 className="mb-35 text-warning fw-bold">{t("Admins")}</h3>
          <h3 className="text-warning fw-bold">Nous vous souhaitons de joyeuses fêtes 🎅 !</h3>

        </div>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <AdminManage toEdit={toEdit} onSuccess={onSuccess} />
              <div className="panel-white mb-30">
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div>
                        <div className="d-flex">
                          <div className="form-group mx-sm-3 mb-0">
                            <input
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              className="form-control"
                              id=""
                              placeholder={t("Recherche")}
                            />
                          </div>
                          <button
                            onClick={handleSearch}
                            type="submit"
                            className="btn btn-default "
                          >
                            {t("Rechercher")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div>
                        <div className="d-flex w-100">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#manageIndustryForm"
                            type="submit"
                            className="btn btn-default ml-auto"
                          >
                            {t("Créer")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-white mb-30">
                <div className="box-padding">
                  {isLoading && (
                    <div className="d-flex">
                      <div className="col-12 mb-10 d-flex">
                        <span
                          className="spinner-border m-auto"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  )}
                  {!isLoading && paginatedData && (
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Prénom")}</th>
                            <th scope="col">{t("Nom")}</th>
                            <th scope="col">{t("Email")}</th>
                            <th scope="col">{t("username")}</th>
                            <th scope="col">{t("service")}</th>
                            <th scope="col">{t("role")}</th>
                            <th scope="col">{t("last_login")}</th>
                            <th scope="col">{t("Actions")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.map((config) => (
                            <tr className="hover" key={config.id}>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.first_name}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.last_name}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.email}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.username}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.service}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.role}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {formatDate(config?.last_login)}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {isManager() && (
                                  <button
                                    onClick={() => handleEdit(config)}
                                    className="btn btn-default p-1 mr-3"
                                    style={{
                                      height: "30px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <img src="/assets/img/edit.svg" alt="" />
                                  </button>
                                )}

                                {isManager() && (
                                <button
                                  onClick={() =>
                                    confirmDeletion(
                                      URLS.USERS.delete(config.id),
                                      t,
                                      onSuccess
                                    )
                                  }
                                  className="btn btn-default p-1"
                                  style={{ height: "30px" }}
                                >
                                  <span className="d-flex">
                                    <LuTrash />
                                  </span>
                                </button>
                              )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* Pagination */}
                  <Pagination
                    currentPage={page}
                    totalItems={filteredData?.length}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminList;
