import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../../services/axios';
import { toast } from 'react-toastify';
import { getUserDetails } from '../../components/utils/functions';
import { URLS } from '../../services/urls';
import Preloader from '../../components/preloader/Preloader';

const Quickmessage = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState({});
  const [userService, setUserService] = useState('');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  // Questions par catégorie de service
  const questionsByCategory = {
    "Service Commercial": [
      "Quelles sont les types de containers les plus vendus en France",
      "Quelles sont les types de Bungalows les plus vendus en France",
      "Comment convaincre un prospect de faire un achat?",
      "Quelle est la meilleure stratégie de vente pour un nouveau produit?"
    ],
    "Service Comptabilité": [
      "Donne moi une formation complète sur la comptabilité en France",
      "Comment se fait la comptabilité au Cameroun?",
      "Quelle est la compatibilité des systèmes?",
      "Comment assurer la compatibilité des versions?"
    ],
    "Service Marketing": [
      "Comment définir une audience cible pour une nouvelle campagne?",
      "Quels sont les outils pour analyser les tendances du marché?",
      "Comment créer une stratégie de marketing digital efficace?",
      "Comment évaluer le ROI d'une campagne publicitaire?"
    ],
    "Service Informatique": [
      "Comment créer et installer un module sur Odoo version 17",
      "Donne moi une formation sur Odoo.sh",
      "Comment administrer un serveur hébergé sur OVH?",
      "Quelles sont les bonnes pratiques pour sécuriser un serveur?"
    ],
    "Service Administratif": [
      "Comment gérer efficacement les documents RH?",
      "Quels sont les outils pour planifier une réunion d'équipe?",
      "Comment créer un plan de gestion de projet?",
      "Comment rédiger un rapport d'activité mensuel?"
    ]
  };

  // Correspondance entre les services et les catégories de questions
  const serviceToCategoryMap = {
    commercial: "Service Commercial",
    comptabilite: "Service Comptabilité",
    marketing: "Service Marketing",
    informatique: "Service Informatique",
    administration: "Service Administratif"
  };

  useEffect(() => {
    const loadUserDetailsAndQuestions = async () => {
      setLoading(true);
      try {
        const user = getUserDetails();
        if (user && user.service) {
          setUserService(user.service);
          setUserRole(user.role); // Récupérer le rôle de l'utilisateur

          // Si l'utilisateur est admin ou manager, il peut voir toutes les catégories
          if (user.role === 'admin' || user.role === 'manager') {
            setQuestions(questionsByCategory); // Toutes les catégories pour admin/manager
          } else {
            // Sinon, afficher uniquement les questions de son service
            const serviceCategory = serviceToCategoryMap[user.service];
            setQuestions({ [serviceCategory]: questionsByCategory[serviceCategory] });
          }
        } else {
          throw new Error('Utilisateur non authentifié ou service/role non défini');
        }
      } catch (error) {
        console.error('Erreur lors du chargement des détails utilisateur ou des questions :', error);
        toast.error("Erreur lors du chargement des données utilisateur.");
      } finally {
        setLoading(false);
      }
    };

    loadUserDetailsAndQuestions();
  }, []);

  const handleNewChat = async (content) => {
    setLoading(true);
    try {
      const user = getUserDetails();
      if (!user || !user.access_token) {
        throw new Error('User not authenticated');
      }

      const newChatData = {
        role: "user",
        content: content,  // Le contenu dynamique selon la question sélectionnée
      };

      console.log('Envoi de la question:', content);
      const response = await API.post(URLS.CHATGPT.chat.createOrGetChat, newChatData, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const newChatId = response.data.chat_id;
      console.log('Nouvel ID de chat créé:', newChatId);
      navigate(`/chat/${newChatId}`);
    } catch (error) {
      console.error('Erreur lors de la création de la discussion:', error);
      toast.error("Erreur lors de la création d'une nouvelle discussion.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />; // Affiche un préloader si les questions ou les détails utilisateur sont encore en cours de chargement
  }

  return (
    <div className="quickmessage-container">
      <h1 className='fw-bold text-light'>{userRole === 'admin' || userRole === 'manager' ? 'Toutes les catégories' : `Questions pour le service ${serviceToCategoryMap[userService]}`}</h1>
      
      {Object.keys(questions).map((category) => (
        <div key={category} className="quickmessage-category">
          <h2>{category}</h2>
          {questions[category].map((question, index) => (
            <div key={index} className="quickmessage-card" onClick={() => handleNewChat(question)}>
              <p>{question}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Quickmessage;
